import {
  IconBike16,
  IconBus16,
  IconCar16,
  IconHighway16,
  IconHospital16,
  IconHouse16,
  IconKid16,
  IconMetroMsk16,
  IconParking16,
  IconPin16,
  IconRestaurant16,
  IconSchool16,
  IconStore16,
  IconWalk16,
} from '@cian/ui-kit';
import { IIconProps } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { IconCompetitor16 } from 'shared/mf-infrastructure/components/Icons/IconCompetitor16';
import { IconNoCar16 } from 'shared/mf-infrastructure/components/Icons/IconNoCar16';
import { IconPolice16 } from 'shared/mf-infrastructure/components/Icons/IconPolice16';
import { IconScooter16 } from 'shared/mf-infrastructure/components/Icons/IconScooter16';
import { IconStreetCar16 } from 'shared/mf-infrastructure/components/Icons/IconStreetCar16';
import { TFutureRoad } from 'shared/mf-infrastructure/types/futureInfrastructure';
import { EInfrastructureType } from 'shared/mf-infrastructure/types/infrastructure';
import {
  EInsideTAInfrastructureType,
  EOutsideTAInfrastructureType,
} from 'shared/mf-infrastructure/types/transportAccessibility';

export const INFRASTRUCTURE_ICON_MAP = new Map<string, React.FunctionComponent<IIconProps>>([
  ['cafe', IconRestaurant16],
  ['clinic', IconHospital16],
  ['grocery', IconStore16],
  ['metro', IconMetroMsk16],
  ['nursery', IconKid16],
  ['futureRoad', IconHighway16],
  ['school', IconSchool16],
  ['similar_objects', IconPin16],
  ['similar_newbuildings', IconHouse16],

  [EOutsideTAInfrastructureType.busStops, IconBus16],
  [EOutsideTAInfrastructureType.tramStops, IconStreetCar16],
  [EOutsideTAInfrastructureType.metro, IconMetroMsk16],
  [EOutsideTAInfrastructureType.parking, IconParking16],
  [EOutsideTAInfrastructureType.bikeParking, IconBike16],
  [EOutsideTAInfrastructureType.chargingStations, IconCompetitor16],
  [EOutsideTAInfrastructureType.scooterParking, IconScooter16],

  [EInsideTAInfrastructureType.yardWithoutCars, IconNoCar16],
  [EInsideTAInfrastructureType.parking, IconParking16],
  [EInsideTAInfrastructureType.dropOffPickUpArea, IconCar16],
  [EInsideTAInfrastructureType.bikeParking, IconBike16],
  [EInsideTAInfrastructureType.chargingStations, IconCompetitor16],
  [EInsideTAInfrastructureType.scooterParking, IconScooter16],
  [EInsideTAInfrastructureType.boulevards, IconWalk16],
  [EInsideTAInfrastructureType.trafficSafetyElements, IconPolice16],
]);

export const INFRASTRUCTURE_TITLE_MAP = new Map<string, string>([
  [EInfrastructureType.SimilarObjects, 'Похожие объявления'],
  [EInfrastructureType.SimilarNewbuildings, 'Похожие ЖК'],
  [EInfrastructureType.Cafe, 'Рестораны'],
  [EInfrastructureType.Clinic, 'Медицинские учреждения'],
  [EInfrastructureType.Grocery, 'Магазины'],
  [EInfrastructureType.Metro, 'Метро'],
  [EInfrastructureType.Nursery, 'Детские сады'],
  [EInfrastructureType.School, 'Школы'],
]);
export const ALWAYS_VISIBLE_INFRASTRUCTURE_TYPES: string[] = ['main', 'futureRoad' as TFutureRoad];
